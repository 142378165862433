<template>
  <div>
    <div class="row scroll p-0 support">
      <div class="col-sm-12 text-left p-16">
        <div v-if="isSubmitted && !errorCode">
          <h3>{{$t('support.title')}}</h3>
          <p>{{$t('support.success')}}</p>
        </div>
        <validation-observer v-slot="{ invalid }" v-else>
          <form>
            <error-notification v-if="errorCode" :errorCode="`${errorCode}`" />
            <legend><h3>{{$t('support.title')}}</h3></legend>
            <div class="row">
              <div class="col-12">
                <validated-input
                  v-model="form.email"
                  id="supportForm_email"
                  :placeholder="$t('support.form.email.placeholder')"
                  :label="$t('support.form.email.label')"
                  rules="required|email"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="form-row">
                    <cm-select :data-label="$t('support.form.topic.label')" :placeholder="$t('support.form.topic.placeholder')" :value="form.topic" @change="setTopic">
                      <cm-select-option slot="option" :data-display="$t('support.form.topic.payments')" value="payments"></cm-select-option>
                      <cm-select-option slot="option" :data-display="$t('support.form.topic.receipts')" value="receipts"></cm-select-option>
                      <cm-select-option slot="option" :data-display="$t('support.form.topic.service')" value="service"></cm-select-option>
                      <cm-select-option slot="option" :data-display="$t('support.form.topic.other')" value="other"></cm-select-option>
                    </cm-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="form.topic === 'receipts'">
              <div class="col-12">
                <validated-input
                  v-model="form.receiptReference"
                  id="supportForm_receiptReference"
                  :placeholder="$t('support.form.receiptReference.placeholder')"
                  :label="$t('support.form.receiptReference.label')"
                  rules="required"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <validated-textarea
                  v-model="form.message"
                  id="supportForm_message"
                  :placeholder="$t('support.form.message.placeholder')"
                  :label="$t('support.form.message.label')"
                  rules="required"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-cta" @click.prevent="submit" :disabled="invalid || loading">{{ $t('support.saveBtn')}}</button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ValidatedInput from '@/components/ValidatedInput'
import ValidatedTextarea from '@/components/ValidatedTextarea'
import apiService from '@/services/apiService'
import ErrorNotification from '@/components/ErrorNotification'

export default {
  name: 'SupportForm',
  components: {
    ValidatedInput,
    ValidatedTextarea,
    ValidationObserver,
    ErrorNotification
  },
  props: {
    prefilEmail: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      form: {
        email: this.prefilEmail,
        topic: 'payments',
        message: '',
        receiptReference: ''
      },
      isSubmitted: false,
      errorCode: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading'
    ])
  },
  methods: {
    ...mapMutations(['setLoading']),
    setTopic (customEvent) {
      this.form.topic = customEvent.detail.value
      this.form.receiptReference = ''
    },
    async submit () {
      this.errorCode = ''
      this.setLoading(true)
      try {
        await apiService.postSupport(
          {
            email: this.form.email,
            topic: this.form.topic,
            receiptReference: this.form.receiptReference,
            message: this.form.message
          }
        )
      } catch (err) {
        this.errorCode = err.response?.status
      } finally {
        this.setLoading(false)
        this.isSubmitted = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.support {
  max-width: 400px;
  margin: 0 auto;
}
</style>
