<template>
  <div class="error-notification"
      :class="{ 'show-notification': errorCode !== null }">
    <span class="cm-icon cm-icon-warning-open"
          aria-hidden="true"></span>
    {{$t(`httpErrors.${errorCode}`)}}
  </div>
</template>

<script>
export default {
  name: 'ErrorNotification',
  props: {
    errorCode: {
      type: String,
      required: false
    }
  },
  computed: {
    errorMessage () {
      const key = `httpErrors.${this.errorCode}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return this.$t('httpErrors._')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.error-notification {
  position: fixed;
  display: none;
  top: 0px;
  left: -40px;
  width: calc(100% + 80px);
  height: 65px;
  color: #fff;
  background: #ff4040;
  @media (min-width: $sm) {
    position: relative;
    left: 0;
    width: auto;
    margin-bottom: 16px;
  }

  .cm-icon {
    font-size: 1.2rem;
    padding-right: 8px;
  }

  &.show-notification {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
