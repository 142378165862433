<template>
  <div class="account-overview-wrapper">
    <div class="account-overview p-32">

      <div class="key border-bottom">{{ $t('account.name') }}</div>
      <div class="value border-bottom">{{ account.firstname }} {{ account.lastname }}</div>

      <div class="key border-bottom">{{ $t('account.email') }}</div>
      <div class="value border-bottom">{{ account.email }}</div>

      <div class="key border-bottom">{{ $t('account.mobile') }}</div>
      <div class="value border-bottom">{{ account.phonenumber }}</div>

      <div class="key">{{ $t('account.address') }}</div>
      <div class="value">
        {{ account.address }} {{ account.housenumber }}<br />
        {{ account.postalcode }} {{ account.city }}
      </div>

      <div class="full-row">
        <button @click="$router.push('/account-details')" class="btn btn-ghost">
          {{ $t('account.editProfile') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const accountNS = createNamespacedHelpers('account')

export default {
  name: 'AccountOverview',
  computed: {
    ...accountNS.mapGetters(['account'])
  }
}
</script>

<style lang="scss" scoped>
.account-overview-wrapper {
  overflow: auto;

  .account-overview {
    display: inline-grid;
    grid-template-columns: 4fr 6fr;
    grid-template-rows: auto;
    font-size: 16px;
    text-align: left;

    .key, .value {
      padding: 8px 8px;
    }
    .value {
      font-weight: 500;
    }
    .border-bottom {
      border-bottom: 1px solid #f1f1f1;
    }
    .full-row {
      grid-column: span 2;
    }
  }
}
</style>
