var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"rules":_vm.rules,"name":_vm.label,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var validated = ref.validated;
  var valid = ref.valid;
  var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-input",class:{
          error: validated && !valid,
          success: validated && valid
        }},[_c('textarea',{staticClass:"form-control",attrs:{"id":_vm.id,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event.target.value)}}}),_c('label',{attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(errors[0])?_c('div',{staticClass:"form-input-validation"},[_c('div',{staticClass:"form-input-notes-left",attrs:{"title":errors[0]}},[_vm._v(_vm._s(errors[0]))])]):_vm._e()])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }