<template>
  <div class="mor-header text-sm-center">
    <div class="row">
      <div class="col-8 col-sm-12">
        <h1 class="title">
          <span
            id="logo"
            :alt="'logo ' + merchantName"
            class="img-logo"
            :style="{ backgroundImage: `url(${logoUrl})` }"
          ></span>

          <span class="d-sm-none text">{{ title }}</span>
        </h1>
      </div>
      <div class="col-4 d-flex d-sm-none text-right language-col">
        <language-switch></language-switch>
      </div>
    </div>
    <div class="row d-none d-sm-flex">
      <div class="col">
        <ul class="menu">
          <li>
            <router-link to="/">
              <span class="cm-icon cm-icon-mor-account" />
              {{ $t('menu.account') }}
            </router-link>
          </li>
          <li>
            <router-link to="/account-details">
              <span class="cm-icon cm-icon-mor-edit-profile" />
              {{ $t('menu.editProfile') }}
            </router-link>
          </li>
          <li>
            <router-link to="/receipts">
              <span class="cm-icon cm-icon-mor-receipts" />
              {{ $t('menu.receipts') }}
            </router-link>
          </li>
          <li>
            <router-link to="/subscriptions">
              <span class="cm-icon cm-icon-mor-subscriptions" />
              {{ $t('menu.subscriptions') }}
            </router-link>
          </li>
          <li>
            <router-link to="/faq">
              <span class="cm-icon cm-icon-mor-faq" />
              {{ $t('menu.faq') }}
            </router-link>
          </li>
          <li>
            <router-link to="/support">
              <span class="cm-icon cm-icon-mor-support" />
              {{ $t('menu.support') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import LanguageSwitch from '@/components/LanguageSwitch.vue'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'MorMerchantHeader',
  components: {
    LanguageSwitch
  },
  props: {
    title: {
      default: '',
      type: String
    }
  },
  computed: {
    ...merchantNS.mapGetters(['merchantName', 'logoUrl'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.mor-header {
  .title {
    display: inline-flex;
    margin: 22px;
    font-size: 14px;
    color: #fff;

    .img-logo {
      background: url($default_logo) no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      border-radius: 50%;
      width: 26px;
      height: 26px;
    }

    .text {
      line-height: 26px;
      padding-left: 8px;
    }
  }

  .language-col {
    flex-direction: row-reverse;
    align-items: center;

    .language-switch {
      color: #fff;
    }
  }

  .menu {
    display: inline-block;

    li::before, &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      border-bottom: 1px solid #1f4cb5;
      border-top: 1px solid #1f4cb5;
    }

    li {
      text-align: left;

      a {
        padding: 14px;
        display: block;
        color: #fff;
        font-size: 16px;

        &.router-link-exact-active, &:hover, &:active, &:focus {
          text-decoration: none;
          color: #eaebed;
        }
      }
    }
  }

  @media (min-width: $sm) {
    height: 100%;

    .title {
      margin: 40px 0 24px;

      .img-logo {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
