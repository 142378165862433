<template>
  <header class="row box-header">
    <div class="col-2">
      <button :aria-label="$t(`btnBack`)"
              v-if="showBackButton"
              @click="backButtonClick()"
              class="btn btn-secondary-icon">
        <span class="cm-icon cm-icon-previous"
              aria-hidden="true"></span>
      </button>
    </div>
    <div class="col-8">
      <h1>{{title}}</h1>
    </div>
    <div class="col-2 text-right">
      <language-switch></language-switch>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguageSwitch from '@/components/LanguageSwitch.vue'

export default {
  name: 'HppBoxHeader',
  components: {
    LanguageSwitch
  },
  props: {
    showBackButton: {
      default: false,
      type: Boolean
    },
    backButtonTarget: {
      required: false,
      default: '/'
    },
    title: {
      default: '',
      type: String
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    async backButtonClick () {
      this.$router.push(this.backButtonTarget)
    }
  }
}
</script>

<style lang="scss" scoped>
.box-header {
  padding: 0;
  height: 38px;
  h1 {
    font-size: 0.9em;
  }
}
</style>
