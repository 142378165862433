<template>
  <div class="row m-t-16">
    <div class="col-6">
      <button v-if="backToAppButton" class="btn btn-ghost-icon-text" @click="$emit('click-back')">
        <span aria-hidden="true" class="cm-icon cm-icon-previous"></span>
        <span class="btn-text">{{$t('btnBackToShop')}}</span>
      </button>
    </div>
    <div class="col-6">
      <button class="btn btn-ghost-icon-text" @click="$emit('click-retry')">
        <span aria-hidden="true" class="cm-icon cm-icon-refresh"></span>
        <span class="btn-text">{{$t('btnRetry')}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'ErrorActions',
  computed: {
    ...merchantNS.mapGetters(['backToAppButton'])
  }
}
</script>
