<template>
  <div class="text-center">
    <mor-box-header :title="$t('account.title')" />
    <template v-if="errorCode">
      <error-notification :errorCode="`${errorCode}`" />
      <error-actions @click-back="$emit('back-to-merchant')" @click-retry="retry"/>
    </template>
    <account-overview />
  </div>
</template>

<script>
import AccountOverview from '@/components/AccountOverview'
import MorBoxHeader from '@/components/MorBoxHeader'
import ErrorActions from '@/components/ErrorActions'
import ErrorNotification from '@/components/ErrorNotification'
import accountMixin from '@/mixins/account'

export default {
  name: 'IndexView',
  mixins: [
    accountMixin
  ],
  components: {
    AccountOverview,
    MorBoxHeader,
    ErrorActions,
    ErrorNotification
  },
  async mounted () {
    await this.requireAccount()
  }
}
</script>
