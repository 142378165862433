<template>
  <div class="merchant-header text-center">
    <div class="row">
      <div class="col-sm-12">
        <span class="logo">
          <span
            id="logo"
            :alt="'logo ' + merchantName"
            class="img-logo"
            :style="{ backgroundImage: `url(${logoUrl})` }"
          ></span>
        </span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-sm-12">
          <h3 :title="merchantName">{{ merchantName }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'HppMerchantHeader',
  computed: {
    ...merchantNS.mapGetters(['merchantName', 'logoUrl'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.merchant-header {
  padding: 20px;
  background-position: center;
  background-size: cover;

  @media (min-width: $sm) {
    background-image: none !important;
    padding: 40px;
    height: 100%;
  }

  h1,
  h2,
  h3 {
    color: #fff;
    margin: 0;
  }

  h1 {
    font-size: 20px;
    white-space: nowrap;
    @media (min-width: $sm) {
      font-size: 32px;
      margin-top: 50px;
      width: 100%;
    }
  }

  h2 {
    $font-size: 12px;
    $line-height: 1.4;
    $lines-to-show: 1;
    font-weight: 400;
    display: block;
    margin: 5px auto;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;

    @media (min-width: $sm) {
      font-weight: 600;
      $font-size: 24px;
      $line-height: 1.2;
      $lines-to-show: 3;
      display: block;
      /* Fallback for non-webkit */
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      height: $font-size * $line-height * $lines-to-show;
      /* Fallback for non-webkit */
      font-size: $font-size;
      line-height: $line-height;
      -webkit-line-clamp: $lines-to-show;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h3 {
    opacity: 0.7;
    font-size: 10px;
    font-weight: 400;
    float: left;

    @media (min-width: $sm) {
      float: none;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .logo {
    display: inline-flex;
    width: 100px;
    height: 100px;
    background: darken(rgba($brand-primary, 0.5), 30%);
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @media (min-width: $sm) {
      width: 135px;
      height: 135px;
      margin: 30% 0 10% 0;
    }

    .img-logo {
      background: url($default_logo) no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    text-align: left;
    margin-top: 10px;

    @media (min-width: $sm) {
      margin-top: 0;
      text-align: center;
    }
  }

  .btn {
    margin-top: 50px;
  }

  /* smaller header on detail page */
  &.small-box {
    @media (min-width: $sm) {
      height: auto !important;
    }

    .logo {
      display: none;

      @media (min-width: $sm) {
        display: inline-flex;
      }
    }
  }
}
</style>
