<template>
  <div class="receipt-details">
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.date') }}
      </div>
      <div class="col-7">
        {{ receipt.invoiceDate | date }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.number') }}
      </div>
      <div class="col-7">
        {{ receipt.invoiceNumber }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.paymentMethod') }}
      </div>
      <div class="col-7">
        {{ receipt.paymentMethod }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.retailer') }}
      </div>
      <div class="col-7">
        {{ receipt.retailer }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.address') }}
      </div>
      <div class="col-7">
        {{ receipt.address }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.vatNumber') }}
      </div>
      <div class="col-7">
        {{ receipt.vatNumber }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.product') }}
      </div>
      <div class="col-7">
        {{ receipt.product }}
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        {{ $t('receiptDetails.vat') }} ({{ receipt.vatPercentage }}%)
      </div>
      <div class="col-7">
        {{ receipt.vatAmount | currency(receipt.currency) }}
      </div>
    </div>
    <div class="row no-border">
      <div class="col-5">
        {{ $t('receiptDetails.total') }}
      </div>
      <div class="col-7">
        {{ receipt.totalAmount | currency(receipt.currency) }}
      </div>
    </div>
    <div class="row no-border">
      <div class="col">
        <a :href="downloadUrl" download>{{ $t('receiptDetails.download') }}</a>
      </div>
    </div>
    <div class="row no-border">
      <div class="col">
        <small>
          <router-link
            :to="`/receipts/${receipt.transactionId}/${receipt.unit}/terms-and-conditions`">
            {{ $t('receiptDetails.terms') }}
          </router-link>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import date from '@/formatters/date'
import currency from '@/formatters/currency'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'ReceiptDetails',
  props: {
    receipt: {
      type: Object,
      required: true
    }
  },
  filters: {
    date,
    currency
  },
  computed: {
    ...merchantNS.mapGetters(['language']),
    downloadUrl () {
      return `${process.env.VUE_APP_API_URL}/api/receipts/${this.receipt.transactionId}/pdf/${this.language}?unit=${this.receipt.unit.toLowerCase()}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.receipt-details {
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
  font-size: 16px;

  .row {
    padding: 14px;
    border-bottom: 1px solid $grey-light;
    color: #2e3748;

    &.no-border {
      border-bottom: 0 none;
    }
  }
}
</style>
