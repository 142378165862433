export default function currency (amount, currency, type = 'full') {
  let numberFormatter
  if (type === 'short' && Number.isInteger(amount)) {
    numberFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  } else {
    numberFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency
    })
  }
  return numberFormatter.format(amount)
}
