<template>
  <div class="text-center">
    <mor-box-header :title="$t('receipts.title')" />
    <template v-if="errorCode">
      <error-notification :errorCode="`${errorCode}`" />
      <error-actions @click-back="$emit('back-to-merchant')" @click-retry="retry"/>
    </template>
    <template v-else>
      <receipt-list :receipts="receipts" />
      <pagination-buttons
        :total="listMetadata.total"
        :skip="listMetadata.skip"
        :take="listMetadata.take"
        @change-page="loadReceipts"
      />
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapMutations } from 'vuex'
import { getReceipts } from '@/services/apiService'
import MorBoxHeader from '@/components/MorBoxHeader'
import ErrorActions from '@/components/ErrorActions'
import ErrorNotification from '@/components/ErrorNotification'
import PaginationButtons from '@/components/PaginationButtons'
import ReceiptList from '@/components/receipt/List'

const receiptsNS = createNamespacedHelpers('receipts')

export default {
  name: 'ReceiptsView',
  components: {
    MorBoxHeader,
    ErrorActions,
    ErrorNotification,
    PaginationButtons,
    ReceiptList
  },
  data () {
    return {
      errorCode: undefined
    }
  },
  computed: {
    ...receiptsNS.mapGetters(['receipts', 'listMetadata'])
  },
  methods: {
    ...receiptsNS.mapMutations(['setReceipts']),
    ...mapMutations(['setLoading']),
    async loadReceipts (skip = 0, take = 5) {
      this.setLoading(true)
      this.errorCode = undefined
      try {
        const receiptsResponse = await getReceipts(skip, take)
        this.setReceipts(receiptsResponse)
      } catch (err) {
        if (err.isAxiosError) {
          this.errorCode = err.response.status
        }
      } finally {
        this.setLoading(false)
      }
    },
    async retry () {
      await this.loadReceipts()
    }
  },
  async mounted () {
    await this.loadReceipts()
  }
}
</script>
