
import { createNamespacedHelpers, mapMutations } from 'vuex'
import apiService from '@/services/apiService'

const accountNS = createNamespacedHelpers('account')

export default {
  data () {
    return {
      errorCode: undefined
    }
  },
  computed: {
    ...accountNS.mapGetters(['account', 'accountAvailable'])
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...accountNS.mapMutations(['setAccount']),
    async requireAccount () {
      if (!this.accountAvailable) {
        await this.loadAccount()
      }
    },
    async loadAccount () {
      this.setLoading(true)
      this.errorCode = undefined
      try {
        const account = await apiService.getAccount()
        this.setAccount({ account })
      } catch (err) {
        if (err.isAxiosError) {
          this.errorCode = err.response.status
        }
      } finally {
        this.setLoading(false)
      }
    }
  }
}
