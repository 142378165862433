const state = {
  subscriptions: []
}

const getters = {
  current: state => state.subscriptions.filter(subscription => subscription.order),
  available: state => state.subscriptions.filter(subscription => !subscription.order)
}

const mutations = {
  setSubscriptions (state, { subscriptions }) {
    state.subscriptions = subscriptions
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
