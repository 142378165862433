import Vue from 'vue'
import PortalVue from 'portal-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

// eslint-disable-next-line no-unused-expressions
// import(/* webpackChunkName: "chunk-aurora" */ '@/components/aurora/webcomponents-loader')
// eslint-disable-next-line no-unused-expressions
// import(/* webpackChunkName: "chunk-aurora" */ '@/components/aurora/aurora.components-legacy')
// eslint-disable-next-line import/first
import './sass/base.scss'

extend('required', {
  ...required,
  computesRequired: true
})
extend('email', email)

Vue.config.productionTip = false
Vue.config.ignoredElements = [/cm-\w*/]

Vue.use(PortalVue)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
