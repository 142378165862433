<template>
  <select :aria-label="$t(`selectLanguage`)"
          v-model="$i18n.locale"
          class="language-switch minimal"
          @change="setHtmlLang($i18n.locale)">
    <option
      v-for="language in languages"
      :key="language"
      :value="language.toLowerCase()"
    >
      {{language}}
    </option>
  </select>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import apiService from '@/services/apiService'
const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'LanguageSwitch',
  computed: {
    ...merchantNS.mapGetters(['languages', 'language'])
  },
  methods: {
    ...merchantNS.mapMutations(['setLanguage', 'setCxClassificationId']),
    async setHtmlLang (language) {
      if (language) {
        const { cxClassificationId } = await apiService.putConfig({
          preferredLanguage: language
        })
        this.setLanguage({ language })
        this.setCxClassificationId({ cxClassificationId })
      }
    }
  },
  watch: {
    language (language) {
      this.$i18n.locale = language
      document.getElementsByTagName('html')[0].setAttribute('lang', language)
    }
  }
}
</script>
