<template>
  <div class="receipts">
    <div class="row title d-none d-sm-flex">
      <div class="col-4 text-left">
        Reference
      </div>
      <div class="col-4 text-left">
        Date
      </div>
      <div class="col-4 text-left">
        Price
      </div>
    </div>
    <div
      v-for="receipt in receipts"
      :key="receipt.transactionID"
      class="row receipt"
      :title="receipt.description">
      <div class="col-6 text-left d-sm-none">
        <div class="row">
          <div class="col">
            {{ receipt.transactionID }}
          </div>
        </div>
        <div class="row">
          <div class="col sub">
            {{ receipt.invoiceDate | date }}
          </div>
        </div>
      </div>
      <div class="col-4 text-left d-none d-sm-flex">
        {{ receipt.transactionID }}
      </div>
      <div class="col-4 text-left d-none d-sm-flex">
        {{ receipt.invoiceDate | date }}
      </div>
      <div class="col-4 col-sm-2 one-line text-left">
        {{ receipt.totalAmount | currency(receipt.currency) }}
      </div>
      <div class="col-2 text-right">
        <router-link :to="{ name:'receiptDetails', params: { id: receipt.transactionID, unit: receipt.unit.toLowerCase() }}" :replace="true">{{ $t('receipts.view') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import currency from '@/formatters/currency'
import date from '@/formatters/date'

export default {
  name: 'ReceiptsList',
  props: {
    receipts: {
      type: Array,
      required: true
    }
  },
  filters: {
    currency,
    date
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.receipts {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;

  .receipt {
    padding: 14px;
    border-bottom: 1px solid $grey-light;
    color: #2e3748;

    .sub {
      color: #aeb9cb;
    }
  }

  .title {
    padding: 14px;
    color: #2e3748;
    font-weight: bold;
  }
}
</style>
