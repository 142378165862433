<template>
  <div class="text-center">
    <hpp-box-header v-if="$route.meta.hppStyle" :title="$t('accountDetails.headerTitle')" />
    <mor-box-header v-else :title="$t('accountDetails.updateHeaderTitle')" />
    <template v-if="errorCode">
      <error-notification :errorCode="`${errorCode}`" />
      <error-actions @click-back="$emit('back-to-merchant')" @click-retry="retry"/>
    </template>
    <account-details-form :newAccount="newAccount" :paymentFlow="paymentFlow" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import HppBoxHeader from '@/components/HppBoxHeader'
import MorBoxHeader from '@/components/MorBoxHeader'
import ErrorActions from '@/components/ErrorActions'
import ErrorNotification from '@/components/ErrorNotification'
import AccountDetailsForm from '@/components/AccountDetailsForm'

export default {
  name: 'AccountDetailsView',
  components: {
    HppBoxHeader,
    MorBoxHeader,
    ErrorActions,
    ErrorNotification,
    AccountDetailsForm
  },
  data () {
    return {
      errorCode: undefined
    }
  },
  computed: {
    newAccount () {
      return this.$route.meta.newAccount
    },
    paymentFlow () {
      return this.$route.meta.paymentFlow
    }
  },
  methods: {
    ...mapMutations(['setLoading'])
  }
}
</script>
