<template>
  <cm-telephone-input
    :value="value"
    @change="change"
    data-change-event-on-init="true"
    :data-error-message="$t('formErrors.notValid')"
    :placeholder="placeholder"
    required
    data-error-required="Required"
    :data-label="label"
    :data-language="language"
  />
</template>

<script>
export default {
  name: 'ValidatedTelephone',
  components: {
  },
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    language: {
      type: String,
      required: false,
      default: 'en'
    }
  },
  methods: {
    change (e) {
      this.$emit('input', e.detail.value)
      this.$emit('valid-change', e.detail.isValid)
    }
  }
}
</script>
