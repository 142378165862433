<template>
  <div class="text-center">
    <mor-box-header :title="$t('support.headerTitle')" />
    <support-form v-if="accountAvailable" :prefilEmail="account.email"/>
  </div>
</template>

<script>
import MorBoxHeader from '@/components/MorBoxHeader'
import SupportForm from '@/components/SupportForm'
import accountMixin from '@/mixins/account'

export default {
  name: 'Support',
  mixins: [
    accountMixin
  ],
  components: {
    MorBoxHeader,
    SupportForm
  },
  async mounted () {
    await this.requireAccount()
  }
}
</script>
