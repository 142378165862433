var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll"},[_c('div',{staticClass:"faq-content"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-input-special"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text","search-component":"","autocomplete":"off","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.getResults]}}),_vm._m(0),_c('cm-autocomplete',{attrs:{"data-linked-input":"input[search-component]"}},_vm._l((_vm.searchResults),function(result){return _c('cm-select-option',{key:result.id,attrs:{"slot":"option","data-use-html":""},on:{"click":function($event){return _vm.searchResultClick(result)}},slot:"option"},[_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(result.question))]),_c('div',{staticClass:"category"},[_vm._v(_vm._s(_vm.getCategoryText(_vm.categories[result.categories[0]])))])])])}),1)],1)])]),_vm._l((_vm.topics),function(topic){return _c('section',{key:("topic-" + (topic.id)),class:{
        open: topic.$open
      }},[_c('h1',{on:{"click":function($event){return _vm.topicClick(topic)}}},[_c('span',{class:{
          'cm-icon': true,
          'cm-icon-add': !topic.$open,
          'cm-icon-remove': topic.$open
        },attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.getCategoryText(topic.name))+" ")]),_c('dl',[_vm._l((topic.items),function(item){return [_c('dt',{key:("question-dt-" + (item.id)),class:{
              open: item.$open,
              loading: item.$loading
            },on:{"click":function($event){return _vm.questionClick(item)}}},[_c('span',{class:{
              'cm-icon': true,
              'cm-icon-add': !item.$open,
              'cm-icon-remove': item.$open
            },attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.question)+" ")]),_c('dd',{key:("question-dd-" + (item.id)),domProps:{"innerHTML":_vm._s(item.$answer)}})]})],2)])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"input-left icon"},[_c('span',{staticClass:"cm-icon cm-icon-search",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }