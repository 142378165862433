<template>
  <div id="app" class="merchantStyle">
    <link rel="stylesheet" type="text/css" :href="cssUrl" @load="stylesheetLoaded = true" @error="stylesheetLoaded = true" />
    <transition name="fade">
      <div v-if="loading" class="loading-overlay">
        <cm-indicator></cm-indicator>
      </div>
    </transition>
    <div class="container p-t-0 root-container" :class="{
      'stylesheet-loaded': stylesheetLoaded
    }">
      <div class="row">

        <div id="box-wrapper" v-if="$route.meta.hppStyle" class="box row">
          <div class="col-sm-6" v-if="$route.meta.hideHeader !== true">
            <hpp-merchant-header></hpp-merchant-header>
          </div>
          <div :class="hppPageClass">
            <keep-alive include="AccountDetailsView">
              <router-view @back-to-merchant="backToMerchant" />
            </keep-alive>
          </div>
        </div>

        <div id="box-wrapper" v-else class="box row">
          <div class="col-12 col-sm-3">
            <mor-merchant-header :title="merchantName"></mor-merchant-header>
          </div>
          <div class="col-12 col-sm-9 page">
            <keep-alive include="ReceiptsView">
              <router-view @back-to-merchant="backToMerchant" />
            </keep-alive>
          </div>
        </div>

        <portal-target name="topRow" slim />

        <button v-if="showBackButton" @click="back()" class="d-sm-none btn btn-ghost-icon-text back-to-shop-link">
          <span aria-hidden="true" class="cm-icon cm-icon-previous"></span>
          <span class="btn-text">{{$t('btnBackToShop')}}</span>
        </button>
        <footer class="row page" v-if="$route.meta.hideFooter !== true">
          <div class="col-sm-12 text-center">
              {{$t('protectedBy')}} <a href="https://www.cm.com" target="_blank">CM.com</a>
          </div>
        </footer>
      </div>
    </div>
    <button v-if="showBackButton" @click="back()" class="d-none d-sm-block btn btn-ghost-icon-text back-to-shop-link">
      <span aria-hidden="true" class="cm-icon cm-icon-previous"></span>
      <span class="btn-text">{{$t('btnBackToShop')}}</span>
    </button>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapMutations } from 'vuex'
import HppMerchantHeader from '@/components/HppMerchantHeader'
import MorMerchantHeader from '@/components/MorMerchantHeader'
import apiService from '@/services/apiService'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'App',
  components: {
    HppMerchantHeader,
    MorMerchantHeader
  },
  data () {
    return {
      errorCode: undefined,
      stylesheetLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'loading'
    ]),
    ...merchantNS.mapGetters([
      'merchantName',
      'returnUrl',
      'cssUrl',
      'backToAppButton'
    ]),
    hppPageClass () {
      const style = {
        page: true,
        'col-sm-6': this.$route.meta.hideHeader !== true,
        'col-sm-12': this.$route.meta.hideHeader === true
      }
      return style
    },
    showBackButton () {
      return this.backToAppButton && this.$route.meta.hideBackButton !== true
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...merchantNS.mapMutations([
      'setLanguages',
      'setLanguage',
      'setMerchantName',
      'setReturnUrl',
      'setLogoUrl',
      'setCssUrl',
      'setCxClassificationId'
    ]),
    back () {
      if (this.$route.meta.backButtonTarget) {
        this.$router.push(this.$route.meta.backButtonTarget)
      } else {
        this.backToMerchant()
      }
    },
    backToMerchant () {
      window.location.href = this.returnUrl
    }
  },
  async mounted () {
    this.setLoading(true)
    this.errorCode = undefined
    try {
      const {
        languages,
        preferredLanguage,
        cxClassificationId,
        merchant
      } = await apiService.getConfig()
      this.setLanguages({ languages })
      this.setLanguage({ language: preferredLanguage })
      this.setMerchantName({ merchantName: merchant.name })
      this.setReturnUrl({ returnUrl: merchant.backUrl })
      this.setLogoUrl({ logoUrl: merchant.logoUrl })
      this.setCssUrl({ cssUrl: merchant.stylesheet })
      this.setCxClassificationId({ cxClassificationId: cxClassificationId })
      if (!this.cssUrl) {
        this.stylesheetLoaded = true
      }
    } catch (err) {
      this.stylesheetLoaded = true
      if (err.isAxiosError) {
        this.errorCode = err.response.status
      } else {
        throw err
      }
    } finally {
      this.setLoading(false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/sass/_variables.scss";
@import "/sass/_variables_client.scss";
#app {
  background: url($default_background) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .back-to-shop-link {
    color: #000;
    border: 1px solid #000;
    background: #fff;
    font-size: 10px;
    margin: 15px 12px;
    height: 32px;
    text-align: left;

    @media (min-width: $sm) {
      position: absolute !important;
      left: 20px;
      top: 24px;
      margin: 0;
    }

    &:hover .btn-text {
      text-decoration: underline;
    }
    .cm-icon {
      top: 3px;
    }
    .btn-text {
      padding-left: 24px;
    }
  }

  .root-container {
    display: none;

    &.stylesheet-loaded {
      display: block;
    }
  }
  @media (max-width: $sm) {
    /* This is more important than an inline style */
    background-image: none !important;

    .root-container {
      margin: 0;
      width: 100%;
      max-width: 100vw;
    }
    .root-container::before {
      display: none !important;
    }
  }
}
</style>
