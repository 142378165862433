
const state = {
  ready: undefined,
  firstname: undefined,
  lastname: undefined,
  address: undefined,
  housenumber: undefined,
  state: undefined,
  postalcode: undefined,
  city: undefined,
  country: undefined,
  email: undefined,
  phonenumber: undefined
}

const getters = {
  account: ({ ...accountData }) => ({
    ...accountData
  }),
  accountAvailable: ({ firstname }) => firstname !== undefined
}

const mutations = {
  setAccount (state, { account }) {
    state.ready = account.ready
    state.firstname = account.firstname
    state.lastname = account.lastname
    state.address = account.address
    state.housenumber = account.housenumber
    state.state = account.state
    state.postalcode = account.postalcode
    state.city = account.city
    state.country = account.country
    state.email = account.email
    state.phonenumber = account.phonenumber
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
