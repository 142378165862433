import htmlAnswers from '@cxco/ui-chat/lib/plugins/htmlAnswers'

// Ugly way to retrieve processAnswer function from @cxco/ui-chat because it is not exposed.
let processAnswer
htmlAnswers.install({
  before (_type, processAnswerFn) {
    processAnswer = processAnswerFn
  }
})

export default {
  renderAnswer (answerObject) {
    // only support the first outputPart of the first output
    // which should not matter since this is not a conversation
    const output = answerObject.outputs[0]
    const outputPart = output.outputParts[0]
    const cxAnswerObject = {
      data: {
        ...outputPart,
        answer: outputPart.text
      },
      metadata: {
        ...outputPart.metadata
      }
    }
    processAnswer(cxAnswerObject)
    return cxAnswerObject.data.answer
  }
}
