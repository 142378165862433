import Vue from 'vue'
import VueRouter from 'vue-router'

import AccountDetails from '@/views/AccountDetails.vue'
import Index from '@/views/Index.vue'
import Receipts from '@/views/Receipts.vue'
import ReceiptDetails from '@/views/ReceiptDetails.vue'
import Subscriptions from '@/views/Subscriptions.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import Support from '@/views/Support'
import Faq from '@/views/Faq'
import PaymentSuccess from '@/views/PaymentSuccess.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/payment/create-account',
    name: 'payment-createAccount',
    component: AccountDetails,
    meta: {
      newAccount: true,
      hppStyle: true,
      paymentFlow: true
    }
  }, {
    path: '/payment/create-account/terms-and-conditions',
    name: 'payment-createAccount-termsAndConditions',
    component: TermsAndConditions,
    meta: {
      backButtonTarget: '/payment/create-account',
      hppStyle: true,
      paymentFlow: true
    }
  }, {
    path: '/payment/terms-and-conditions',
    name: 'termsAndConditions',
    component: TermsAndConditions,
    meta: {
      accountAlreadyAvailable: true,
      hppStyle: true,
      paymentFlow: true
    }
  }, {
    path: '/payment/success',
    name: 'paymentSuccess',
    component: PaymentSuccess,
    meta: {
      hppStyle: true,
      hideHeader: true,
      hideBackButton: true,
      hideFooter: true
    }
  }, {
    path: '',
    name: 'index',
    component: Index
  }, {
    path: '/subscriptions',
    name: 'subscriptions',
    component: Subscriptions
  }, {
    path: '/receipts',
    name: 'receipts',
    component: Receipts
  }, {
    path: '/receipts/:id/:unit',
    name: 'receiptDetails',
    component: ReceiptDetails,
    meta: {
      backButtonTarget: { name: 'receipts' }
    },
    children: [
      {
        path: '/receipts/:id/:unit/terms-and-conditions',
        name: 'receipt-termsAndConditions',
        component: TermsAndConditions
      }
    ]
  }, {
    path: '/account-details',
    name: 'accountDetails',
    component: AccountDetails
  }, {
    path: '/support',
    name: 'Support',
    component: Support
  }, {
    path: '/faq',
    name: 'Faq',
    component: Faq
  }, {
    path: '/create-account',
    name: 'createAccount',
    component: AccountDetails,
    meta: {
      newAccount: true,
      hppStyle: true,
      paymentFlow: false
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
