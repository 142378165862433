<template>
  <div class="terms-and-conditions">
    <hpp-box-header v-if="$route.meta.hppStyle" :title="$t('terms.title')" :backButtonTarget="backButtonTarget || $route.meta.backButtonTarget" :showBackButton="!$route.meta.accountAlreadyAvailable" />
    <mor-box-header v-else :title="$t('terms.title')" :backButtonTarget="backButtonTarget || $route.meta.backButtonTarget" :showBackButton="!$route.meta.accountAlreadyAvailable" />
    <template v-if="errorCode">
      <error-notification :errorCode="`${errorCode}`" />
      <error-actions @click-back="$router.push('/')" @click-retry="getTerms"/>
    </template>
    <div class="row scroll">
      <section class="col-sm-12 content" v-html="terms">
      </section>

      <portal to="topRow">
        <div v-if="$route.meta.accountAlreadyAvailable" class="instantAccept row d-block d-sm-none">
          <div class="col-12">
            <div class="form-group terms-group"
                align="left">
              <div class="form-row">
                <div class="form-check">
                  <label>
                    <input type="checkbox"
                          v-model="form.terms"
                          class="form-control" />
                    <span class="label terms-label">
                      {{$t('payment.terms.label[0]')}} {{$t('payment.terms.label[1]')}}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn-cta" :disabled="!form.terms" @click="acceptTerms">
              {{ $t('payment.continueBtn') }}
            </button>
          </div>
        </div>
      </portal>
      <div v-if="$route.meta.accountAlreadyAvailable" class="instantAccept-sm row d-none d-sm-block">
        <div class="col-12">
          <div class="form-group terms-group"
              align="left">
            <div class="form-row">
              <div class="form-check">
                <label>
                  <input type="checkbox"
                        v-model="form.terms"
                        class="form-control" />
                  <span class="label terms-label">
                    {{$t('payment.terms.label[0]')}} {{$t('payment.terms.label[1]')}}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <button class="btn btn-cta" :disabled="!form.terms || loading" @click="acceptTerms">
            {{ $t('payment.continueBtn') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapMutations, mapGetters } from 'vuex'

import apiService from '@/services/apiService'

import ErrorActions from '@/components/ErrorActions'
import ErrorNotification from '@/components/ErrorNotification'
import HppBoxHeader from '@/components/HppBoxHeader'
import MorBoxHeader from '@/components/MorBoxHeader'
const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'TermsAndConditionsView',
  props: {
    backButtonTarget: {
      required: false
    }
  },
  data () {
    return {
      form: {
        terms: false
      },
      terms: undefined,
      errorCode: undefined
    }
  },
  components: {
    ErrorActions,
    ErrorNotification,
    HppBoxHeader,
    MorBoxHeader
  },
  async mounted () {
    await this.getTerms()
  },
  watch: {
    async language () {
      await this.getTerms()
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...merchantNS.mapGetters(['language'])
  },
  methods: {
    ...mapMutations(['setLoading']),
    async getTerms () {
      this.setLoading(true)
      this.errorCode = undefined
      try {
        if (this.$route.params.id) {
          this.terms = await apiService.getReceiptTerms(this.$route.params.id, this.$route.params.unit, this.language)
        } else {
          this.terms = await apiService.getTerms(this.language)
        }
      } catch (err) {
        if (err.isAxiosError) {
          this.errorCode = err.response.status
        } else {
          throw err
        }
      } finally {
        this.setLoading(false)
      }
    },
    async acceptTerms () {
      this.setLoading(true)
      this.errorCode = undefined
      try {
        await apiService.acceptTerms(this.language)
        window.location = '/api/payment'
      } catch (err) {
        if (err.isAxiosError) {
          this.errorCode = err.response.status
        }
      } finally {
        this.setLoading(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.instantAccept {
  padding: 24px 16px;

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  background: #fff;
  box-shadow: 0 -6px 6px rgba(173, 185, 202, 0.42);

  button {
    width: 100%;
  }
}

.content /deep/ {
  line-height: 1.5rem;
  h1 {
    font-size: 14px;
  }
  h1 + p {
    margin-top: 0;
  }
}
</style>
