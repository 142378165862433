
const state = {
  list: [],
  listMetadata: {
    total: 0,
    skip: 0,
    take: 5
  },
  details: undefined
}

const getters = {
  receipts: state => state.list,
  listMetadata: state => state.listMetadata,
  details: state => state.details
}

const mutations = {
  setReceipts (state, { data, total, skip, take }) {
    state.list = data
    state.listMetadata = {
      total,
      skip,
      take
    }
  },
  setDetails (state, { details }) {
    state.details = details
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
