<template>
  <div class="text-center">
    <mor-box-header :title="$t('faq.headerTitle')" />
    <template v-if="errorCode">
      <error-notification :errorCode="`${errorCode}`" />
      <error-actions @click-back="$emit('back-to-merchant')" @click-retry="retry"/>
    </template>
    <faq-content @error="setError" />
  </div>
</template>

<script>
import MorBoxHeader from '@/components/MorBoxHeader'
import ErrorActions from '@/components/ErrorActions'
import ErrorNotification from '@/components/ErrorNotification'
import FaqContent from '@/components/FaqContent'
import accountMixin from '@/mixins/account'

export default {
  name: 'Faq',
  mixins: [
    accountMixin
  ],
  components: {
    MorBoxHeader,
    ErrorActions,
    ErrorNotification,
    FaqContent
  },
  data () {
    return {
      errorCode: undefined
    }
  },
  methods: {
    setError (code) {
      this.errorCode = code
    }
  }
}
</script>
