<template>
  <div class="pagination-buttons">
    <button @click="gotoFirst" :disabled="currentPageIndex === 0">&lt;</button>
    <button
      v-for="index in visiblePagesIndexes"
      :key="index"
      :class="{ active: index === currentPageIndex}"
      @click="changePage(index)"
    >
      {{index + 1}}
    </button>
    <button @click="gotoLast" :disabled="currentPageIndex === pages - 1">&gt;</button>
  </div>
</template>

<script>
export default {
  name: 'PaginationButtons',
  props: {
    total: {
      type: Number,
      required: true
    },
    skip: {
      type: Number,
      required: true
    },
    take: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages () {
      return Math.max(Math.ceil(this.total / this.take), 1)
    },
    currentPageIndex () {
      return Math.floor(this.skip / this.take)
    },
    visiblePagesIndexes () {
      const displayArray = [this.currentPageIndex]
      const targetLength = 3
      while (displayArray.length !== targetLength) {
        let updated = false
        const lastVal = displayArray[displayArray.length - 1]
        if (lastVal < this.pages - 1) {
          updated = true
          displayArray.push(lastVal + 1)
        }
        if (displayArray.length !== targetLength) {
          if (displayArray[0] > 0) {
            updated = true
            displayArray.unshift(displayArray[0] - 1)
          }
        }
        if (!updated) {
          break
        }
      }
      return displayArray
    }
  },
  methods: {
    changePage (index) {
      this.$emit('change-page', this.take * index, this.take)
    },
    gotoFirst () {
      this.changePage(0)
    },
    gotoLast () {
      this.changePage(this.pages - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
$button-primary: #3882fc;
$button-secondary: #e7f2ff;

.pagination-buttons {
  margin: 12px 10px;

  button {
    background: $button-secondary;
    color: $button-primary;
    width: 36px;
    height: 36px;
    font-weight: bold;
    border: 0 none;
    border-radius: 100%;
    padding: 0;
    margin: 0 5px;
    cursor: pointer;

    &:hover, &.active {
      background: $button-primary;
      color: $button-secondary;
    }
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>
