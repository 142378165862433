<template>
  <div class="text-center">
    <mor-box-header :title="$t('subscriptions.title')" />
    <subscription-list />
  </div>
</template>

<script>
import MorBoxHeader from '@/components/MorBoxHeader'
import SubscriptionList from '@/components/SubscriptionList'

export default {
  name: 'subscriptionsView',
  components: {
    MorBoxHeader,
    SubscriptionList
  }
}
</script>
