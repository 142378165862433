<template>
  <div class="row justify-content-center">
    <div class="col-9 col-md-4 align-self-center p-b-64">
      <div class="payment-success-img" aria-hidden="true"></div>
      <h2>{{ $t('success.title')}}</h2>
      <p v-html="message"></p>
      <div class="p-t-16">
        <button class="btn btn-block btn-cta bg-blue-payment-success" @click="backToMerchant">{{ $t('success.btnReturn')}}</button>
      </div>
    </div>
  </div>
</template>

<script>

const Time = {
  SECOND: 1000
}

export default {
  name: 'PaymentSuccess',
  data () {
    return {
      delay: 3 * Time.SECOND,
      countDownTimer: null,
      intervalID: null
    }
  },
  methods: {
    backToMerchant () {
      if (window.MiuiJsBridge) {
        window.clearInterval(this.intervalID)
        window.MiuiJsBridge.invoke('com.miui.player.hybrid.feature.FinishPage', 'sync', null, null)
      }
    },
    countDown () {
      this.countDownTimer = Math.max(this.countDownTimer - 1, 0)
      if (this.countDownTimer === 0) {
        this.backToMerchant()
      }
    }
  },
  computed: {
    message () {
      return this.$t('success.message').replace('{{0}}', `<span class="payment-success-highlight">${this.countDownTimer}</span>`)
    }
  },
  mounted () {
    this.countDownTimer = this.delay / Time.SECOND
    this.intervalID = window.setInterval(this.countDown, Time.SECOND)
  }
}
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_variables_client.scss";

  .bg-blue-payment-success {
    background-color: #4A2FEB
  }
  .row {
    height:100%;

  }
  .payment-success-img {
    background: url('../assets/success.svg');
    background-size: 100%;
    width: 116px;
    height: 101px;
    margin: 24px auto 60px auto;
    @media (min-width: $sm) {
      width: 232px;
      height: 202px;
      margin: 16px auto 60px auto;
    }
  }

  /deep/ .payment-success-highlight {
    font-weight: bold;
    color: #4A2FEB
  }
</style>
