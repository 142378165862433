<template>
  <div class="text-center" v-if="$route.matched.length === 1">
    <mor-box-header :title="$t('receipts.title')" :showBackButton="true" />
    <template v-if="errorCode">
      <error-notification :errorCode="`${errorCode}`" />
      <error-actions @click-back="$router.push('/')" @click-retry="retry"/>
    </template>
    <receipt-details v-else-if="$route.matched.length === 1 && details" :receipt="details" />
  </div>
  <router-view v-else :backButtonTarget="{ name:'receiptDetails', params: $route.params}" />
</template>

<script>
import { createNamespacedHelpers, mapMutations } from 'vuex'
import { getReceipt } from '@/services/apiService'
import MorBoxHeader from '@/components/MorBoxHeader'
import ErrorActions from '@/components/ErrorActions'
import ErrorNotification from '@/components/ErrorNotification'
import ReceiptDetails from '@/components/receipt/Details'

const receiptsNS = createNamespacedHelpers('receipts')

export default {
  name: 'ReceiptDetailsView',
  components: {
    MorBoxHeader,
    ErrorActions,
    ErrorNotification,
    ReceiptDetails
  },
  data () {
    return {
      errorCode: undefined
    }
  },
  computed: {
    ...receiptsNS.mapGetters(['details']),
    id () {
      return this.$route.params.id
    },
    unit () {
      return this.$route.params.unit
    }
  },
  methods: {
    ...receiptsNS.mapMutations(['setDetails']),
    ...mapMutations(['setLoading']),
    async loadReceipt (id, unit) {
      this.setLoading(true)
      this.errorCode = undefined
      this.setDetails({
        details: undefined
      })
      try {
        const details = await getReceipt(id, unit)
        details.transactionId = this.$route.params.id
        this.setDetails({
          details
        })
      } catch (err) {
        if (err.isAxiosError) {
          this.errorCode = err.response.status
        }
      } finally {
        this.setLoading(false)
      }
    },
    async retry () {
      await this.loadReceipt(this.id, this.unit)
    }
  },
  async mounted () {
    await this.loadReceipt(this.id, this.unit)
  }
}
</script>
