import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

export async function getConfig () {
  const { data } = await axios.get('/api/config')
  return data
}

export async function putConfig (payload) {
  const { data } = await axios.put('/api/config', payload)
  return data
}

export async function getReceipts (skip, take) {
  const { data } = await axios.get('/api/receipts', {
    params: { skip, take }
  })
  return data
}

export async function getReceipt (id, unit) {
  const { data } = await axios.get(`/api/receipts/${id}`, {
    params: { unit }
  })
  return data
}

export async function getReceiptTerms (id, unit, language) {
  const { data } = await axios.get(`/api/receipts/${id}/terms-and-conditions/${language.toLowerCase()}`, {
    params: { unit }
  })
  return data.text
}

export async function getTerms (language) {
  const { data } = await axios.get(`/api/terms-and-conditions/${language.toLowerCase()}`)
  return data.text
}

export async function acceptTerms (language) {
  return axios.post(`/api/terms-and-conditions/${language.toLowerCase()}/accept`)
}

export async function getAccount () {
  const { data } = await axios.get('/api/account/me')
  return data
}

export async function putAccount (payload) {
  return axios.put('/api/account/me', payload)
}

export async function postSupport (payload) {
  return axios.post('/api/support', payload)
}

export async function getSubscriptions () {
  const { data } = await axios.get('/api/subscriptions')
  return data
}

export async function cancelSubscription (subscriptionId) {
  return axios.post(`/api/subscriptions/${subscriptionId}/cancel`)
}

export async function selectSubscription (subscriptionId) {
  return axios.post(`/api/subscriptions/${subscriptionId}/select`)
}

export async function getFaqTree (classificationId, language) {
  const { data } = await axios.get(`/api/cx/classifications/${classificationId}/categories`, {
    params: {
      culture: language
    }
  })
  return data
}

export async function getAnswer (id, classificationId, language) {
  const { data } = await axios.get(`/api/cx/faqs/${id}/output`, {
    params: {
      classificationId,
      culture: language
    }
  })
  return data
}

export async function searchFaqs (q, classificationId, language) {
  const { data } = await axios.get('/api/cx/faqs', {
    params: {
      classificationId,
      culture: language,
      searchText: q
    }
  })
  return data
}

export default {
  getConfig,
  putConfig,
  getReceipts,
  getReceipt,
  getReceiptTerms,
  getTerms,
  acceptTerms,
  getAccount,
  putAccount,
  postSupport,
  getSubscriptions,
  cancelSubscription,
  selectSubscription,
  getFaqTree,
  getAnswer,
  searchFaqs
}
