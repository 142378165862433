<template>
  <div>
    <div class="row scroll p-0">
      <div class="account-details-form col-sm-12 text-left p-16 p-l-32 p-r-32">
        <validation-observer ref="formObserver">
          <form>
            <legend><h3>{{$t('accountDetails.title')}}</h3></legend>
            <div class="row">
              <div class="col-12 col-sm-6 fixed-sm-p-r-32">
                <validated-input
                  v-model="form.firstname"
                  id="accountForm_firstname"
                  class="m-r-8"
                  :placeholder="$t('accountDetails.form.firstname.placeholder')"
                  :label="$t('accountDetails.form.firstname.label')"
                  rules="required"
                />
              </div>
              <div class="col-12 col-sm-6">
                <validated-input
                  v-model="form.lastname"
                  id="accountForm_lastname"
                  :placeholder="$t('accountDetails.form.lastname.placeholder')"
                  :label="$t('accountDetails.form.lastname.label')"
                  rules="required"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <validated-input
                  v-model="form.email"
                  id="accountForm_email"
                  :placeholder="$t('accountDetails.form.email.placeholder')"
                  :label="$t('accountDetails.form.email.label')"
                  rules="required|email"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <validated-telephone
                  v-model="form.phonenumber"
                  @valid-change="phoneValid = $event"
                  :placeholder="$t('accountDetails.form.phonenumber.placeholder')"
                  :label="$t('accountDetails.form.phonenumber.label')"
                  :language="language"
                />
              </div>
            </div>
            <div class="row m-t-16">
              <div class="col-12">
                <h3 class="legend">{{$t('accountDetails.addressTitle')}}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <cm-address-picker
                  :data-value-street-number="streetNumber"
                  :data-value-zip="form.postalcode"
                  :data-value-city="form.city"
                  :data-value-subarea="form.state"
                  :data-value-countryid="form.country"
                  :data-locale="language"
                  :data-label-street="$t('accountDetails.form.street.label')"
                  :data-label-zip="$t('accountDetails.form.zip.label')"
                  :data-label-city="$t('accountDetails.form.city.label')"
                  :data-label-subarea="$t('accountDetails.form.subarea.label')"
                  :data-label-country="$t('accountDetails.form.country.label')"

                  :data-required-error-street="$t('formErrors.required')"
                  :data-required-error-number="$t('formErrors.required')"
                  :data-required-error-zip="$t('formErrors.required')"
                  :data-required-error-city="$t('formErrors.required')"
                  :data-required-error-country="$t('formErrors.required')"
                  data-required-fields="street,number,zip,city,country"
                  @change="setAddress"
                />
              </div>
            </div>
            <div class="row" v-if="requireTermsAccept">
              <div class="col-12">
                <div class="form-group terms-group"
                    align="left">
                  <div class="form-row">
                    <div class="form-check">
                      <label>
                        <input type="checkbox"
                              v-model="form.terms"
                              class="form-control" />
                        <span class="label terms-label">
                          {{$t('payment.terms.label[0]')}}
                          <router-link to="/payment/create-account/terms-and-conditions">{{$t('payment.terms.label[1]')}}</router-link>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-cta" :disabled="!valid || loading" @click.prevent="submit">{{ submitButtonText }}</button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapMutations, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ValidatedInput from '@/components/ValidatedInput'
import ValidatedTelephone from '@/components/ValidatedTelephone'
import apiService from '@/services/apiService'
import accountMixin from '@/mixins/account'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'AccountDetailsForm',
  mixins: [
    accountMixin
  ],
  components: {
    ValidatedInput,
    ValidatedTelephone,
    ValidationObserver
  },
  props: {
    newAccount: {
      type: Boolean,
      required: false,
      default: false
    },
    paymentFlow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      form: {
        firstname: '',
        lastname: '',
        address: '',
        housenumber: '',
        state: '',
        postalcode: '',
        city: '',
        country: '',
        email: '',
        phonenumber: '',
        terms: false
      },
      addressValid: false,
      phoneValid: false
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...merchantNS.mapGetters(['language']),
    valid () {
      if (this.requireTermsAccept && !this.form.terms) {
        return false
      }
      return this.phoneValid && this.addressValid && this.$refs.formObserver?.flags.valid
    },
    requireTermsAccept () {
      return this.newAccount && this.paymentFlow
    },
    streetNumber () {
      return `${this.form.address} ${this.form.housenumber}`
    },
    submitButtonText () {
      if (this.newAccount) {
        if (this.paymentFlow) {
          return this.$t('payment.continueBtn')
        }
        return this.$t('accountDetails.createBtn')
      }
      return this.$t('accountDetails.saveBtn')
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    setAddress (event) {
      const { detail } = event
      if (!detail) {
        return
      }
      this.form.address = detail.street
      this.form.housenumber = detail.number
      this.form.state = detail.subarea1
      this.form.postalcode = detail.zip
      this.form.city = detail.city
      this.form.country = detail.countryID
      this.addressValid = detail.valid
    },
    async submit () {
      this.setLoading(true)
      this.errorCode = undefined
      try {
        await apiService.putAccount({
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          address: this.form.address,
          housenumber: this.form.housenumber,
          state: this.form.state,
          postalcode: this.form.postalcode,
          city: this.form.city,
          country: this.form.country,
          email: this.form.email,
          phonenumber: this.form.phonenumber
        })
        await this.loadAccount()
        if (this.requireTermsAccept) {
          await apiService.acceptTerms(this.language)
        }
        if (this.paymentFlow) {
          this.continuePayment()
        } else {
          this.$router.push('/')
        }
      } catch (err) {
        if (err.isAxiosError) {
          this.errorCode = err.response.status
        }
      } finally {
        this.setLoading(false)
      }
    },
    continuePayment () {
      window.location = '/api/payment'
    }
  },
  async mounted () {
    if (!this.newAccount) {
      await this.requireAccount()
      this.form = {
        ...this.account
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
@media (min-width: $sm) {
  .fixed-sm-p-r-32 {
    padding-right: 32px;
  }
}

.account-details-form {
  max-width: 500px;
  margin: 0 auto;
}
</style>
