<template>
  <div class="scroll">
    <div class="faq-content">
      <div class="form-group">
        <div class="form-row">
          <div class="form-input-special">
            <input type="text" v-model="search" @input="getResults" search-component autocomplete="off" class="form-control" placeholder="Search"/>
            <span class="input-left icon">
              <span aria-hidden="true" class="cm-icon cm-icon-search"></span>
            </span>
            <cm-autocomplete data-linked-input="input[search-component]" >
              <cm-select-option
                v-for="result in searchResults"
                :key="result.id"
                slot="option"
                data-use-html
                @click="searchResultClick(result)"
              >
                <li>
                  <div class="title">{{ result.question }}</div>
                  <div class="category">{{ getCategoryText(categories[result.categories[0]]) }}</div>
                </li>
              </cm-select-option>
            </cm-autocomplete>
          </div>
        </div>
      </div>

      <section
        v-for="topic in topics"
        :key="`topic-${topic.id}`"
        :class="{
          open: topic.$open
        }"
      >
        <h1 @click="topicClick(topic)">
          <span :class="{
            'cm-icon': true,
            'cm-icon-add': !topic.$open,
            'cm-icon-remove': topic.$open
          }" aria-hidden="true"></span>

          {{ getCategoryText(topic.name) }}
        </h1>
        <dl>
          <template v-for="item in topic.items">
            <dt
              :key="`question-dt-${item.id}`"
              @click="questionClick(item)"
              :class="{
                open: item.$open,
                loading: item.$loading
              }"
            >
              <span :class="{
                'cm-icon': true,
                'cm-icon-add': !item.$open,
                'cm-icon-remove': item.$open
              }" aria-hidden="true"></span>
              {{ item.question }}
            </dt>
            <dd :key="`question-dd-${item.id}`" v-html="item.$answer"></dd>
          </template>
        </dl>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import apiService from '@/services/apiService'
import cxService from '@/services/cxService'

const merchantNS = createNamespacedHelpers('merchant')

export default {
  name: 'FaqContent',
  data () {
    return {
      search: '',
      searchResults: [],
      topics: [],
      categories: {}
    }
  },
  computed: {
    ...merchantNS.mapGetters(['language', 'cxClassificationId']),
    questions () {
      return this.topics.map(topic => topic.questions).flat()
    }
  },
  methods: {
    async getDefaultFaqs () {
      const { result } = await apiService.getFaqTree(this.cxClassificationId, this.language)
      this.mapCategories(result)
      this.topics = result
    },
    mapCategories (categories) {
      categories.forEach(category => {
        this.categories[category.id] = category.name
        this.mapCategories(category.subCategories)
      })
    },
    topicClick (topic) {
      Vue.set(topic, '$open', !topic.$open)
    },
    async questionClick (question, open) {
      Vue.set(question, '$open', open ?? !question.$open)
      if (question.$answer || question.$loading) {
        return
      }
      try {
        Vue.set(question, '$loading', true)
        const result = await apiService.getAnswer(question.id, this.cxClassificationId, this.language)
        const answer = cxService.renderAnswer(result)
        Vue.set(question, '$answer', answer)
      } catch (err) {
        Vue.set(question, '$open', false)
        if (err.isAxiosError) {
          return this.$emit('error', err.response.status)
        }
        console.error(err)
      } finally {
        Vue.set(question, '$loading', false)
      }
    },
    async searchResultClick (result) {
      let questionClickPromise
      this.topics.forEach(topic => {
        Vue.set(topic, '$open', false)
        topic.items.forEach(question => {
          if (question.id === result.id) {
            Vue.set(topic, '$open', true)
            questionClickPromise = this.questionClick(question)
          } else {
            Vue.set(question, '$open', false)
          }
        })
      })
      await questionClickPromise
      await Vue.nextTick()
      this.$el.querySelector('dt.open + dd').scrollIntoView()
    },
    async getResults () {
      if (this.debounceTimerId) {
        return
      }
      await new Promise(resolve => {
        this.debounceTimerId = setTimeout(resolve, 300)
      })

      try {
        const { result } = await apiService.searchFaqs(this.search, this.cxClassificationId, this.language)
        this.searchResults = result.matches
      } catch (err) {
        if (err.isAxiosError) {
          return this.$emit('error', err.response.status)
        }
        console.error(err)
      } finally {
        delete this.debounceTimerId
      }
    },
    getCategoryText (title) {
      const key = `faq.topics.${title.replace(/[^a-zA-Z0-9-_]+/g, '')}`
      if (this.$te(key)) {
        return this.$t(key)
      } else {
        return title
      }
    }
  },
  watch: {
    cxClassificationId () {
      this.getDefaultFaqs()
    }
  },
  async mounted () {
    await this.getDefaultFaqs()
  }
}
</script>

<style lang="scss" scoped>
.faq-content {
  max-width: 400px;
  margin: 0 auto;
  text-align: left;

  .cm-icon {
    color: #3969e1;
  }

  section {
    margin-bottom: 24px;
    border: 2px solid #e6e9ea;
    border-radius: 5px;

    h1 {
      position: relative;
      padding: 24px 44px 24px 12px;
      font-size: 16px;
      user-select: none;
      cursor: pointer;

      .cm-icon {
        position: absolute;
        top: 24px;
        right: 12px;
      }
    }

    dl {
      display: none;
      margin: 0;
      background-color: #f6f6f6;
    }

    &.open dl {
      display: block;
    }

    dt {
      position: relative;
      font-size: 14px;
      padding: 16px 44px 16px 12px;
      user-select: none;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 12px;
        right: 12px;
        height: 2px;
        bottom: -1px;
        background: #e6e9ea;
      }

      &:last-of-type::after {
        display: none;
      }

      .cm-icon {
        position: absolute;
        top: 16px;
        right: 12px;
      }
    }

    dd {
      display: none;
      padding: 12px;
      margin: 0;
      font-size: 12px;
      line-height: 24px;
    }

    dt.open + dd {
      display: block;
    }
  }
}

li {
  cursor: pointer;

  .title {
    font-size: 14px;
  }
  &:hover .title {
    color: #3969e1;
  }
  .category {
    font-size: 12px;
    color: #adb9ca;
  }
}
</style>
