<template>
  <validation-provider
    :rules="rules"
    v-slot="{
      validated,
      valid,
      errors
    }"
    :name="label"
    slim
  >
  <div class="form-group">
    <div class="form-row">
      <div class="form-input"
          :class="{
            error: validated && !valid,
            success: validated && valid
          }">
        <input type="text"
              :value="value"
              @input="$emit('input', $event.target.value)"
              @change="$emit('change', $event.target.value)"
              @blur="$emit('blur', $event.target.value)"
              class="form-control"
              :id="id"
              :placeholder="placeholder">
        <label :for="id">
          {{ label }}
        </label>
        <div v-if="errors[0]" class="form-input-validation">
          <div class="form-input-notes-left"
              :title="errors[0]">{{errors[0]}}</div>
        </div>
      </div>
    </div>
  </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ValidatedInput',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
