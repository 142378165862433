
const state = {
  name: '',
  returnUrl: undefined,
  logoUrl: '',
  cssUrl: '',
  languages: [],
  language: 'en',
  backToAppButton: false,
  cxClassificationId: 1
}

const getters = {
  merchantName: state => state.name,
  returnUrl: state => state.returnUrl,
  logoUrl: state => state.logoUrl,
  cssUrl: state => state.cssUrl,
  languages: state => state.languages,
  language: state => state.language,
  backToAppButton: state => !!state.returnUrl,
  cxClassificationId: state => state.cxClassificationId
}

const mutations = {
  setLanguages (state, { languages }) {
    state.languages = languages.map(l => l.toLowerCase())
  },
  setLanguage (state, { language }) {
    state.language = language.toLowerCase()
  },
  setMerchantName (state, { merchantName }) {
    state.name = merchantName
  },
  setReturnUrl (state, { returnUrl }) {
    state.returnUrl = returnUrl
  },
  setLogoUrl (state, { logoUrl }) {
    state.logoUrl = logoUrl
  },
  setCssUrl (state, { cssUrl }) {
    state.cssUrl = cssUrl
  },
  setCxClassificationId (state, { cxClassificationId }) {
    state.cxClassificationId = cxClassificationId
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
