<template>
  <div class="subscriptions scroll">
    <template v-if="subscriptionToCancel">
      <subscription-card
        v-for="subscription in current"
        :key="subscription.id"
        :subscription="subscription"
        :cancelConfirmation="true"
        @confirm-cancelation="cancelSubscription"
        @cancel-cancelation="subscriptionToCancel = undefined" />
    </template>
    <template v-else>
      <h2>{{$t('subscriptions.current')}}</h2>
      <subscription-card
        v-for="subscription in current"
        :key="subscription.id"
        :subscription="subscription"
        @cancel="setSubscriptionToCancel" />

      <h2>{{$t('subscriptions.available')}}</h2>

      <subscription-card
        v-for="subscription in available"
        :key="subscription.id"
        :subscription="subscription"
        @select="selectSubscription" />
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapMutations } from 'vuex'
import SubscriptionCard from '@/components/SubscriptionCard'
import apiService from '@/services/apiService'

const subscriptionNS = createNamespacedHelpers('subscriptions')

export default {
  name: 'SubscriptionList',
  components: {
    SubscriptionCard
  },
  data () {
    return {
      subscriptionToCancel: undefined,
      errorCode: undefined
    }
  },
  computed: {
    ...subscriptionNS.mapGetters([
      'current',
      'available'
    ])
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...subscriptionNS.mapMutations(['setSubscriptions']),
    async loadSubscriptions () {
      this.errorCode = ''
      this.setLoading(true)
      try {
        this.setSubscriptions(await apiService.getSubscriptions())
      } catch (err) {
        this.errorCode = err.response?.status
      } finally {
        this.setLoading(false)
      }
    },
    setSubscriptionToCancel (subscription) {
      this.subscriptionToCancel = subscription
    },
    async cancelSubscription (subscription) {
      this.errorCode = ''
      this.setLoading(true)
      try {
        await apiService.cancelSubscription(subscription.id)
        await this.loadSubscriptions()
      } catch (err) {
        this.errorCode = err.response?.status
      } finally {
        this.setLoading(false)
        this.subscriptionToCancel = undefined
      }
    },
    async selectSubscription (subscription) {
      this.errorCode = ''
      this.setLoading(true)
      try {
        await apiService.selectSubscription(subscription.id)
        await this.loadSubscriptions()
      } catch (err) {
        this.errorCode = err.response?.status
      } finally {
        this.setLoading(false)
      }
    }
  },
  mounted () {
    this.loadSubscriptions()
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";

.subscriptions {
  padding: 50px;
  text-align: left;

  h2 {
    font-size: 16px;
    margin-bottom: 16px;
  }
}
</style>
