<template>
  <section
    :class="{
      subscription: true,
      [key]: true,
      card
    }">
    <header v-if="card">
      <i18n :path="`subscriptions.${key}.title.base`" tag="span">
        <strong>{{ $t(`subscriptions.${key}.title.type`) }}</strong>
      </i18n>
    </header>
    <div class="subscription-content">
      <div v-if="cancelConfirmation">
        <strong>
          {{
            $t('subscriptions.cancelConfirm.confirmText', [
              $t(`subscriptions.${key}.title.base`, [$t(`subscriptions.${key}.title.type`)])
            ])
          }}
        </strong>
      </div>
      <div v-if="card" class="price d-none d-sm-block">
        {{ subscription.price | currency(subscription.currency, 'short')}}
        <small>{{ $t('subscriptions.perMonth') }}</small>
      </div>
      <p>{{ $t(`subscriptions.${key}.description`) }}</p>
      <ul>
        <li><span class="cm-icon cm-icon-check" aria-hidden="true"></span> {{ $t(`subscriptions.${key}.usp1`) }}</li>
        <li><span class="cm-icon cm-icon-check" aria-hidden="true"></span> {{ $t(`subscriptions.${key}.usp2`) }}</li>
        <li><span class="cm-icon cm-icon-check" aria-hidden="true"></span> {{ $t(`subscriptions.${key}.usp3`) }}</li>
      </ul>
      <div v-if="card" class="price d-sm-none">
        {{ subscription.price | currency(subscription.currency, 'short')}}
        <small>{{ $t('subscriptions.perMonth') }}</small>
      </div>
      <template v-if="card">
        <div class="selection" v-if="subscription.order">
          <div class="row">
            <div class="col-12 col-lg-4">{{ $t('subscriptions.paymentMethod') }}:</div>
            <div class="col-12 col-lg-6 value">{{ subscription.order.paymentMethod }}</div>
            <div class="col-12 col-lg-2 change-link"><a href="javascript: void(0)" @click.prevent="change">{{ $t('subscriptions.changeLink') }}</a></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">{{ $t('subscriptions.startDate') }}:</div>
            <div class="col-12 col-lg-6 value">{{ subscription.order.startDate | date}}</div>
          </div>
          <div class="row" v-if="subscription.order.endDate">
            <div class="col-12 col-lg-4">{{ $t('subscriptions.endDate') }}:</div>
            <div class="col-12 col-lg-6 value">{{ subscription.order.endDate | date}}</div>
          </div>
        </div>
        <button v-if="subscription.order" class="btn btn-ghost" @click="$emit('cancel', subscription)">{{ $t('subscriptions.cancelButton') }}</button>
        <button v-if="!subscription.order" class="btn btn-ghost" @click="$emit('select', subscription)">{{ $t('subscriptions.selectButton') }}</button>
      </template>
      <template v-if="cancelConfirmation">
        <div class="row">
          <div class="col-6">
            <button class="btn btn-cta" @click="$emit('confirm-cancelation', subscription)">
              {{ $t('subscriptions.cancelConfirm.confirmBtn') }}
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-secondary" @click="$emit('cancel-cancelation', subscription)">
              {{ $t('subscriptions.cancelConfirm.cancelBtn') }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import currency from '@/formatters/currency'
import date from '@/formatters/date'

export default {
  name: 'SubscriptionList',
  props: {
    subscription: {
      type: Object,
      required: true
    },
    cancelConfirmation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    key () {
      return this.subscription.code.toLowerCase()
    },
    card () {
      return !this.cancelConfirmation
    }
  },
  filters: {
    currency,
    date
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_variables_client.scss";
.subscription {
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 32px;

  @media (min-width: $sm) {
    font-size: 16px;
  }

  &.card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);

    .subscription-content {
      padding: 12px 24px;
    }
  }

  header {
    padding: 20px;
    color: #fff;
    font-size: 16px;
    border-radius: 10px 10px 0 0;
  }

  &.premium header {
    background-image: linear-gradient(29deg, #2c18f4 0%, #f91fd4 100%);
  }

  &.basic header {
    background-image: linear-gradient(29deg, #18b8f4 0%, #f91fd4 100%);
  }

  .subscription-content {
    .price {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px;

      @media (min-width: $sm) {
        text-align: right;
        float: right;
      }

      small {
        font-size: 12px;
        font-weight: normal;

        @media (min-width: $sm) {
          display: block;
        }
      }
    }

    ul {
      margin-bottom: 24px;

      .cm-icon {
        vertical-align: sub;
        color: #00dc9a;
      }
    }

    .selection {
      border-radius: 10px;
      background-color: #f6f6f6;
      padding: 20px 24px;
      margin: 0 0 40px;

      .value {
        font-weight: bold;

        @media (min-width: $lg) {
          font-weight: normal;
        }
      }

      .change-link {
        margin-bottom: 16px;
        @media (min-width: $lg) {
          margin-bottom: 0;
        }
      }

    }
  }

  .btn {
    font-weight: 600;
    padding: 6px 40px;

    &.btn-ghost {
      color: #32374b;
    }
  }
}
</style>
