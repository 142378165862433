<template>
  <div>
    <header class="row box-header d-none d-sm-flex">
      <div class="col-2">
        <button :aria-label="$t(`btnBack`)"
                v-if="showBackButton"
                @click="backButtonClick()"
                class="btn btn-secondary-icon">
          <span class="cm-icon cm-icon-previous"
                aria-hidden="true"></span>
        </button>
      </div>
      <div class="col-8">
        <h1>{{title}}</h1>
      </div>
      <div class="col-2 text-right">
        <language-switch></language-switch>
      </div>
    </header>
    <header class="box-sm-header d-sm-none">
      <cm-select ref="mobileMenu" data-icons placeholder="Please select" class="menu-select" @cm-option-clicked="menuClicked" :value="$route.path">
        <cm-select-option
          value="/"
          slot="option"
          :data-display="$t('menu.account')"
          data-icon="mor-account"
        />
        <cm-select-option
          value="/account-details"
          slot="option"
          :data-display="$t('menu.editProfile')"
          data-icon="mor-edit-profile"
        />
        <cm-select-option
          value="/receipts"
          slot="option"
          :data-display="$t('menu.receipts')"
          data-icon="mor-receipts"
        />
        <cm-select-option
          value="/subscriptions"
          slot="option"
          :data-display="$t('menu.subscriptions')"
          data-icon="mor-subscriptions"
        />
        <cm-select-option
          value="/faq"
          slot="option"
          :data-display="$t('menu.faq')"
          data-icon="mor-faq"
        />
        <cm-select-option
          value="/support"
          slot="option"
          :data-display="$t('menu.support')"
          data-icon="mor-support"
        />
      </cm-select>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguageSwitch from '@/components/LanguageSwitch.vue'

export default {
  name: 'MorBoxHeader',
  components: {
    LanguageSwitch
  },
  props: {
    showBackButton: {
      default: false,
      type: Boolean
    },
    backButtonTarget: {
      required: false,
      default: '/'
    },
    title: {
      default: '',
      type: String
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    async backButtonClick () {
      this.$router.push(this.backButtonTarget)
    },
    menuClicked (option) {
      this.$router.push(option.detail.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.box-header {
  padding: 0;
  height: 38px;
  h1 {
    font-size: 0.9em;
  }
}

.box-sm-header {
  background: #32374b;
  flex-direction: column-reverse;
}

.menu-select {
  position: relative;
  top: -4px;
  width: 16rem;
  max-width: 100vw;
}

cm-select-option /deep/ {
  li {
    padding-left: 7px;
  }
  .withIcon {
    text-align: left;
  }
}
</style>
