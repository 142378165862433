import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import merchant from './modules/merchant'
import receipts from './modules/receipts'
import subscriptions from './modules/subscriptions'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    account,
    merchant,
    receipts,
    subscriptions
  },
  state: {
    loading: 0
  },
  getters: {
    loading: state => !!state.loading
  },
  mutations: {
    setLoading (state, loading) {
      if (loading) {
        state.loading++
      } else {
        state.loading--
      }
    }
  }
})
